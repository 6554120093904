import * as React from "react";
import * as style from "./styles/about.module.scss";
import { useState } from "react";
import { graphql } from "gatsby";
import uuid from "react-uuid";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { AnimatePresence } from "framer-motion";
import { getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useMediaQuery } from "react-responsive";
import { mobileBreakpoint } from "components/Mediaqueries";
import { useDisableBodyScroll } from "../utils/useDisableBodyScroll";
import PageWrapper from "components/PageWrapper";
import HeaderSection from "components/HeaderSection/";
import BoundedSection from "components/BoundedSection/";
import IntroSection from "components/IntroSection";
import AwesomeQuote from "components/AwesomeQuote";
import Cta from "components/Cta";
import JoinUsModal from "components/JoinUsModal";
import GalleryParallax from "components/GalleryParallax";

import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import WorkspacePremiumTwoToneIcon from "@mui/icons-material/WorkspacePremiumTwoTone";
import VolunteerActivismTwoToneIcon from "@mui/icons-material/VolunteerActivismTwoTone";
import LocalLibraryTwoToneIcon from "@mui/icons-material/LocalLibraryTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import MorphingScrollImg from "components/MorphingScrollImg";

const slides = [
  {
    img: (data) => data && getImage(data.slider1),
    alt: "",
  },
  {
    img: (data) => data && getImage(data.slider2),
    alt: "",
  },
];

const Slider = ({ data }) => {
  const isMobile = useMediaQuery(mobileBreakpoint);
  return (
    <BoundedSection
      className={style.Slider}
      boundedClassName={style.Slider_wrapper}
      data-cursor-size="120px"
      data-cursor-color="#081832"
    >
      <Swiper
        enabled={isMobile}
        slidesPerView={isMobile ? 1.25 : 2}
        spaceBetween={isMobile ? 16 : 32}
        slidesPerGroup={isMobile ? 1 : 2}
        loop={true}
        loopFillGroupWithBlank={true}
        className="mySwiper"
      >
        {slides.map(({ img, alt }) => (
          <SwiperSlide key={uuid()}>
            <MorphingScrollImg img={img(data)} alt={alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </BoundedSection>
  );
};

const Value = ({ icon, title, description }) => {
  return (
    <div className={style.Value}>
      <div>
        <h4 className={style.Value_title}>
          {icon}
          {title}
        </h4>
        <div className={style.Value_description}>{description}</div>
      </div>
    </div>
  );
};

const Values = ({ t }) => (
  <BoundedSection className={style.Values}>
    <h3 className={style.Values_pretitle}>{t("about.values.preTitle")}</h3>
    <p className={style.Values_title}>{t("about.values.title")}</p>
    <div className={style.Values_grid}>
      <Value
        icon={<GroupsTwoToneIcon />}
        title={t("about.value.unity.title")}
        description={<Trans i18nKey="about.value.unity.description" />}
      />
      <Value
        icon={<WorkspacePremiumTwoToneIcon />}
        title={t("about.value.quality.title")}
        description={<Trans i18nKey="about.value.quality.description" />}
      />
      <Value
        icon={<VolunteerActivismTwoToneIcon />}
        title={t("about.value.modesty.title")}
        description={
          <Trans
            i18nKey="about.value.modesty.description"
            components={{ ul: <ul />, li: <li /> }}
          />
        }
      />
      <Value
        icon={<LocalLibraryTwoToneIcon />}
        title={t("about.value.improvement.title")}
        description={<Trans i18nKey="about.value.improvement.description" />}
      />
    </div>
  </BoundedSection>
);

const AboutPage = ({ data }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);
  useDisableBodyScroll(modalOpen);
  return (
    <PageWrapper
      seoTitle={t("about.seo.title")}
      seoDescription={t("about.seo.description")}
    >
      <HeaderSection
        preTitle={t("about.header.preTitle")}
        title={t("about.header.title")}
        description={<p>{t("about.header.description")}</p>}
      />
      <Slider data={data} />
      <IntroSection>
        <Trans i18nKey="about.intro" />
      </IntroSection>
      <AwesomeQuote
        img={data && getImage(data.awesomeQuoteImg)}
        quote={<Trans i18nKey="about.quote" components={{ span: <span /> }} />}
      />
      <Values t={t} />
      <GalleryParallax data={data} />
      <Cta
        title={
          <Trans i18nKey="about.cta.title" components={{ span: <span /> }} />
        }
        description={t("about.cta.description")}
        onBtnClick={() => (modalOpen ? close() : open())}
        btn={<EmailTwoToneIcon />}
      />
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {modalOpen && <JoinUsModal modalOpen={modalOpen} handleClose={close} />}
      </AnimatePresence>
    </PageWrapper>
  );
};

export default AboutPage;

export const data = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    slider1: file(relativePath: { eq: "misc/scastro_csuarez.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    slider2: file(relativePath: { eq: "misc/mrivas_jiglesias.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    parallax1: file(relativePath: { eq: "misc/jgarcia_drodriguez.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    parallax2: file(
      relativePath: { eq: "misc/meeting_omartinez_mmuino_aperez_jiglesias.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    awesomeQuoteImg: file(
      relativePath: { eq: "misc/jmpineiro_omartinez.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
  }
`;

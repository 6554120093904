import * as React from "react";
import * as style from "./galleryParallax.module.scss";
import BoundedSection from "../BoundedSection/";
import Parallax from "../Parallax";
import MorphingScrollImg from "../MorphingScrollImg/";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { getImage } from "gatsby-plugin-image";

const GalleryParallax = ({ data }) => {
  const { ref, inView } = useInView({
    threshold: 0.8,
  });
  return (
    <BoundedSection className={style.GalleryParallax}>
      <motion.div
        ref={ref}
        style={{ position: "relative" }}
        animate={{ zIndex: inView ? 1 : -1, opacity: inView ? 1 : 0.7 }}
        layout
        transition={{
          type: "spring",
          delay: 0,
          duration: 1,
        }}
      >
        <Parallax distance="-0.2" className={style.GalleryParallax_img}>
          <MorphingScrollImg
            threshold={0.9}
            img={data && getImage(data.parallax1)}
            alt=""
          />
        </Parallax>
      </motion.div>

      <Parallax distance="-0.15" className={style.GalleryParallax_img}>
        <MorphingScrollImg
          threshold={0.9}
          img={data && getImage(data.parallax2)}
          alt=""
        />
      </Parallax>
    </BoundedSection>
  );
};

export default GalleryParallax;
